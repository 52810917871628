
















































import Home from "./views/Home.vue";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {
    Home
  }
})
export default class App extends Vue {
  get appStyle() {
    if (this.$route.path == "/guilds") {
      return "guild";
    }
    return "home";
  }
}
