import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/impressum",
    name: "Impressum",
    component: () =>
      import(/* webpackChunkName: "impressum" */ "../views/Impressum.vue")
  },
  {
    path: "/privacy",
    name: "Datenschutzerklärung",
    component: () =>
      import(/* webpackChunkName: "privacy" */ "../views/Datenschutz.vue")
  },
  {
    path: "/guilds",
    name: "Gilden",
    component: () =>
      import(/* webpackChunkName: "privacy" */ "../views/Gilden.vue")
  },
  {
    path: "/things",
    name: "Nützliche Tools",
    component: () =>
      import(/* webpackChunkName: "things" */ "../views/Things.vue")
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
