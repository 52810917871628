import "@fortawesome/fontawesome-free/css/all.css";
import "typeface-roboto/index.css";

import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "fa"
  },
  theme: {
    themes: {
      light: {
        primary: "#ffa500",
        secondary: "#3e4f5e",
        accent: "#cddc39",
        error: "#8bc34a",
        warning: "#4caf50",
        info: "#009688",
        success: "#00bcd4"
      }
    }
  }
});
